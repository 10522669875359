// return labels object based on language (nl/fr)

const labels = {
    nl: {
        labels: {
            "title": "LOA Informatie",
            "companyName": "Firmanaam",
            "companyType": "Vennootschapsvorm",
            "name": "Naam",
            "firstName": "Voornaam",
            "address": "Adres",
            "houseNumber": "Huisnummer",
            "appendix": "Bus",
            "zipcode": "Postcode",
            "city": "Plaatsnaam",
            "country": "Land",
            "iban": "IBAN",
            "bic": "BIC",
            "vatNumber": "BTW-nummer",
            "bank": "Naam Bank",
            "signLocation": "Plaats",
            "signDate": "Datum",
            "signature": "Handtekening",
            "questionHasServices": "Bent u momenteel op het aansluitadres in het bezit van één of meerdere vaste diensten voor internet of televisie van één of meerdere andere aanbieders?",
            "questionDoItYourself": "Ik wil zelf al mijn huidige diensten opzeggen of ik wil ze behouden. In dit geval hoef ik de onderstaande informatie niet in te vullen.",
            "currentProvider": "Huidige aanbieder",
            "customerNumber": "Klantnummer",
            "easySwitchId": "Uw Easy Switch ID",
            "questionWhen": "Wanneer wilt  uw huidige diensten opzeggen?",
            "moveToNewProvider": "Overzetten naar mijn nieuwe aanbieder",
            "asap": "Zo snel mogelijk",
            "currentNumberInfo": "Uw bestaande (mobiele) telefoonnummers. Indien u uw nummers wilt overzetten of verwijderen, vermeldt u deze hieronder.",
            "delete": "Verwijderen",
            "datum": "Datum",
            "yes": "Ja",
            "no": "Nee",
        }
    },
    fr: {
        labels: {
            "title": "Informations LOA",
            "companyName": "Nom de l'entreprise",
            "companyType": "Forme juridique",
            "name": "Nom",
            "firstName": "Prénom",
            "address": "Adresse",
            "houseNumber": "Numéro de maison",
            "appendix": "Boîte",
            "zipcode": "Code postal",
            "city": "Ville",
            "country": "Pays",
            "iban": "IBAN",
            "bic": "BIC",
            "vatNumber": "Numéro de TVA",
            "bank": "Nom de la banque",
            "signLocation": "Lieu",
            "signDate": "Date",
            "signature": "Signature",
            "questionHasServices": "Avez-vous actuellement un ou plusieurs services fixes pour Internet ou la télévision d'un ou plusieurs autres fournisseurs à l'adresse de connexion?",
            "questionDoItYourself": "Je veux annuler tous mes services actuels moi-même ou je veux les conserver. Dans ce cas, je n'ai pas besoin de remplir les informations ci-dessous.",
            "currentProvider": "Fournisseur actuel",
            "customerNumber": "Numéro de client",
            "easySwitchId": "Votre Easy Switch ID",
            "questionWhen": "Quand voulez-vous annuler vos services actuels?",
            "moveToNewProvider": "Transférer à mon nouveau fournisseur",
            "asap": "Dès que possible",
            "currentNumberInfo": "Vos numéros de téléphone (mobiles) existants. Si vous souhaitez transférer ou supprimer vos numéros, veuillez les indiquer ci-dessous.",
            "delete": "Supprimer",
            "datum": "Date",
            "yes": "Oui",
            "no": "Non",
        }
    }
}

export const getLabels = (language) => {
    return labels[language].labels;
}
