import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {arrayPop, arrayPush, change, Field, formValues, getFormValues, registerField} from 'redux-form';
import {renderProductField} from './blocks/renderField';
import {renderPrice} from './blocks/renderPrice';

class ProductGroupQuestion extends React.Component {
    static propTypes = {
        productGroup: PropTypes.object.isRequired,
    };

    componentDidMount() {
        const {product, productGroup} = this.props;
        const {questionType, questionOptions, question} = productGroup;
        const fieldName = 'product_' + product.id + '_choice';
        this.props.registerField(fieldName);

        if (questionType === 'one' && questionOptions.length > 0) {
            this.props.dispatch(change('wizard', fieldName, questionOptions[0].id.toString()));
        }
    }

    handleInputChange = event => {
        const {product, pushArray, popArray, formData} = this.props;
        const {productGroup} = this.props;
        const fieldName = 'product_' + product.id + '_choice';
        const {target: {name, value}} = event;

        if (productGroup.questionType === 'multiple') {
            const values = formData[fieldName] || [];

            pushArray('wizard', fieldName, value);
        }
        else {
            this.props.dispatch(change('wizard', fieldName, value));
        }

    }

    buildCostString(option) {
        let costString = '';
        if (option.oncePriceEffect || option.monthlyPriceEffect) {
            costString = ' (';

            if (option.oncePriceEffect) {
                costString += '€ ' + renderPrice(option.oncePriceEffect);
            }

            if (option.oncePriceEffect && option.monthlyPriceEffect) {
                costString += ' / ';
            }

            if (option.monthlyPriceEffect) {
                costString += '€ ' + renderPrice(option.monthlyPriceEffect) + ' p/m';
            }

            costString += ')';
        }

        return costString;
    }

    render() {
        const {productGroup, product, formData} = this.props;

        if (!productGroup || !productGroup.question || !productGroup.questionType || productGroup.questionOptions.length === 0) {
            return null;
        }

        if (product.amount_included_in_package > 0 && false === product.is_multiple || formData['product_' + product.id]) {
            const {questionType, questionOptions, question} = productGroup;
            const fieldName = `product_${product.id}_choice`;

            return <div className="productGroupQuestion">
                <p>{question}</p>
                <div className="productGroupQuestion__options">
                    {questionOptions.map((option, index) => {
                        const {id, name} = option;
                        let costString = this.buildCostString(option, product);

                        if (questionType === 'multi') {
                            return <div key={index} className="productGroupQuestion__option">
                                <Field component="input" id={`product_${product.id}_choice_${index}`} name={`${fieldName}_mult_${id}`} type="checkbox" value={id} />
                                <label htmlFor={`product_${product.id}_choice_${index}`}>{name} {costString}</label>
                            </div>;
                        } else if (questionType === 'one') {
                            return <div key={index} className="productGroupQuestion__option">
                                <Field component="input" id={`product_${product.id}_choice_${index}`} name={fieldName} type="radio" value={id.toString()} />
                                <label htmlFor={`product_${product.id}_choice_${index}`}>{name} {costString}</label>
                            </div>
                        }
                    })}
                </div>
            </div>;
        }

        return null;
    }

}

const mapStateToProps = state => ({
    formData: getFormValues('wizard')(state)
});

// const mapDispatchToProps = {
//     pushArray: arrayPush,
//     popArray: arrayPop,
// };

const mapDispatchToProps = dispatch => ({
    pushArray: arrayPush,
    popArray: arrayPop,
    dispatch: dispatch,
    registerField: field => dispatch(registerField('wizard', field, 'Field')),
});

export default connect(mapStateToProps, mapDispatchToProps)(formValues(props => ({value: props.name}))(ProductGroupQuestion));
