import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import renderField from '../../../components/blocks/renderField';
import {getLabels} from '../../const/labels';


class TransferPhoneInput extends React.Component {
    static propTypes = {
        fields: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.labels = getLabels('nl');
    }

    render() {
        const {fields} = this.props;

        return fields.map((number, index) => <div key={`loaNumber${index}`} className="p-3 my-4 w-100 loa-phone-field-container" style={{border: '1px solid #ccc', borderRadius: '5px'}}>
            <div className="relative flex between items-center loa-phone-field" style={{marginBottom: '10px'}}>
                <Field name={`${number}.number`} component={renderField} label="Telefoonnummer" className="mb-4" />
                <div className="number-btn-container">
                    {index === 0 && <button type="button"  className="accent pointer" onClick={() => fields.push({loaTransfer: 'transfer'})}>
                        <i className="fas fa-plus" />
                    </button>}
                    {index > 0 && <button type="button" className="warning pointer" onClick={() => fields.remove(index)}>
                        <i className="fas fa-times" />
                    </button>}
                </div>
            </div>

            <div className="w-100">
                <div className="form__input-wrapper form__input-wrapper--radio form__input-wrapper--force-column">
                    <div className="form__radio-wrapper">
                        <Field component="input" type="radio" id="radio-transfer" name={`${number}.loaTransfer`} value="transfer" />
                        <div className="form__radio" />
                        <label htmlFor="radio-transfer" className="form__label">{this.labels.moveToNewProvider}</label>
                    </div>

                    <div className="form__radio-wrapper">
                        <Field component="input" type="radio" id="radio-delete" name={`${number}.loaTransfer`} value="delete" />
                        <div className="form__radio" />
                        <label htmlFor="radio-delete" className="form__label">{this.labels.delete}</label>
                    </div>
                </div>
            </div>

        </div>);
    }
}

export default TransferPhoneInput;
