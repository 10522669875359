import React from 'react';
import {
    arrayPop,
    arrayPush,
    Field,
    FieldArray,
    formValueSelector,
    getFormValues,
    reduxForm,
    registerField
} from 'redux-form';
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import renderField from '../../components/blocks/renderField';
import {getLabels} from '../const/labels';
import TransferPhoneInput from './providerTransfer/TransferPhoneInput';
import validate from '../helpers/loaValidation';

class LoaInformationForm extends React.Component {
    PropTypes = {
        formData: PropTypes.object,
        language: PropTypes.string,
        popArray: PropTypes.func.isRequired,
        pushArray: PropTypes.func.isRequired,
        register: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.labels = getLabels('nl');
        this.state = {
            hasServices: true,
            diy: false,
        };
    }

    componentDidMount() {
        const {initialized} = this.props;

        // Register all fields
        this.props.register('loaCompany');
        this.props.register('loaCompanyType');
        this.props.register('loaFirstName');
        this.props.register('loaLastName');
        this.props.register('loaVat');
        this.props.register('loaStreet');
        this.props.register('loaNumber');
        this.props.register('loaAppendix');
        this.props.register('loaZipcode');
        this.props.register('loaCity');
        this.props.register('loaHasServices');
        this.props.register('loaDiy');
        this.props.register('loaCurrentProvider');
        this.props.register('loaCustomerNumber');
        this.props.register('loaEasySwitchID');
        this.props.register('loaAsap');

        this.props.pushArray(`loaNumbers`, {loaTransfer: 'transfer', number: ''});
        this.props.change('loaHasServices', 'yes');
        this.props.change('loaAsap', true);

        this.populateForm();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {language, formData, loa} = this.props;

        if (prevProps.language !== language) {
            this.labels = getLabels(language);
        }

        if (prevProps.formData.loaHasServices !== formData.loaHasServices) {
            this.setState({hasServices: formData.loaHasServices !== 'no'});
        }

        if(prevProps.formData.loaDiy !== formData.loaDiy) {
            this.setState({diy: formData.loaDiy});
        }
    }

    populateForm = () => {
        const {formData} = this.props;

        const {
            company,
            vat,
            initials,
            lastname,
            street,
            housenr,
            appendix,
            zipcode,
            city,
            country,
            iban,
            bic,
        } = formData;

        this.props.change('loaCompany', company);
        this.props.change('loaFirstName', initials);
        this.props.change('loaLastName', lastname);
        this.props.change('loaVat', vat);
        this.props.change('loaStreet', street);
        this.props.change('loaNumber', housenr);
        this.props.change('loaAppendix', appendix);
        this.props.change('loaZipcode', zipcode);
        this.props.change('loaCity', city);
    }

    render() {
        const {hasServices, diy} = this.state;
        const {handleSubmit, formData , installation} = this.props;

        return (
            <>
                <div className="form__category">
                    <h2 className="h2 card__title">{this.labels.title}</h2>

                    <div className="form__group form__group--half">
                        <Field name="loaCompany" type="text" component={renderField} label={this.labels.companyName} />
                        <Field name="loaCompanyType" type="text" component={renderField} label={this.labels.companyType} />
                    </div>

                    <div className="form__group form__group--half">
                        <Field name="loaFirstName" type="text" component={renderField} label={this.labels.firstName} />
                        <Field name="loaLastName" type="text" component={renderField} label={this.labels.name} />
                    </div>

                    <div className="form__group">
                        <Field name="loaVat" type="text" component={renderField} label={this.labels.vatNumber} />
                    </div>

                    <div className="form__group form__group--half flex-nowrap">
                        <Field name="loaStreet" type="text" component={renderField} label={this.labels.address} />
                        <div className="form__group form__group--half m-0">
                            <Field name="loaNumber" type="text" component={renderField} label={this.labels.houseNumber} />
                            <Field name="loaAppendix" type="text" component={renderField} label={this.labels.appendix} />
                        </div>
                    </div>

                    <div className="form__group form__group--half">
                        <Field name="loaZipcode" type="text" component={renderField} label={this.labels.zipcode} />
                        <Field name="loaCity" type="text" component={renderField} label={this.labels.city} />
                    </div>

                    <div className="form__group">
                        <p className="mb-4">{this.labels.questionHasServices}</p>
                        <div className="form__input-wrapper form__input-wrapper--radio form__input-wrapper--force-column">
                            <div className="form__radio-wrapper">
                                <Field component="input" type="radio" id="radio-services-yes" name="loaHasServices" value="yes" />
                                <div className="form__radio" />
                                <label htmlFor="radio-services-yes" className="form__label">{this.labels.yes}</label>
                            </div>

                            <div className="form__radio-wrapper">
                                <Field component="input" type="radio" id="radio-services-no" name="loaHasServices" value="no" />
                                <div className="form__radio" />
                                <label htmlFor="radio-services-no" className="form__label">{this.labels.no}</label>
                            </div>
                        </div>
                    </div>

                    {hasServices && <>
                        <div className="form__group">
                            <div className="form__input-wrapper">
                                <div className="form__checkbox-wrapper w-100">
                                    <Field name="loaDiy" id="loaDiy" component="input" type="checkbox" value="yes"/>
                                    <div className="form__checkbox" />
                                    <label htmlFor="loaDiy" className="form__label">{this.labels.questionDoItYourself}</label>
                                </div>
                            </div>
                        </div>

                        {!diy && <>
                            <div className="form__group">
                                <Field name="loaCurrentProvider" type="text" component={renderField} label={this.labels.currentProvider} />
                            </div>
                            <div className="form__group">
                                <Field name="loaCustomerNumber" type="text" component={renderField} label={this.labels.customerNumber} />
                            </div>
                            <div className="form__group">
                                <Field name="loaEasySwitchID" type="text" component={renderField} label={this.labels.easySwitchId} />
                            </div>

                            <div className="form__group">
                                <div className="form__input-wrapper">
                                    <p className="py-4 w-100">{this.labels.questionWhen}</p>

                                    <div className="form__group form__group--half w-100">

                                        <div className="form__checkbox-wrapper">
                                            <Field name="loaAsap" id="loaAsap" component="input" type="checkbox" value="yes"/>
                                            <div className="form__checkbox" />
                                            <label htmlFor="loaAsap" className="form__label">{this.labels.asap}</label>
                                        </div>
                                        {!this.props.formData.loaAsap && <Field name="loaAsapDate" type="date" component={renderField} label="Einddatum" />}
                                    </div>


                                </div>
                            </div>

                            <div className="form__group">
                                <p>Uw bestaande (mobiele) telefoonnummers. Indien u uw nummers wilt overzetten of verwijderen, vermeldt u deze hieronder.</p>

                                <FieldArray name={`loaNumbers`} component={TransferPhoneInput} />
                            </div>
                        </>}
                    </>}
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    pushArray: (field, item) => dispatch(arrayPush('wizard', field, item)),
    popArray: arrayPop,
    register: field => dispatch(registerField('wizard', field, 'Field')),
});

LoaInformationForm = reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    initialValues: {
        loaHasServices: 'yes',
        loaDiy: false,
        loaAsap: true,
        loaFirstName: '',
        loaLastName: '',
        loaVat: '',
        loaStreet: '',
        loaNumber: '',
        loaAppendix: '',
        loaZipcode: '',
        loaCity: '',
        loaCurrentProvider: '',
        loaCustomerNumber: '',
        loaEasySwitchID: '',
        loaAsapDate: '',
    },
    validate,
})(LoaInformationForm);

const selector = formValueSelector('wizard');
LoaInformationForm = connect(
    state => ({
        hasInvoiceAddress: selector(state, 'differentInvoiceAddress'),
        discount: selector(state, 'discount'),
        formData: getFormValues('wizard')(state),
    }),
    mapDispatchToProps
)(LoaInformationForm);

export default LoaInformationForm;
