import React from 'react';
import {
    Field,
    formValueSelector,
    getFormValues,
    reduxForm,
    registerField
} from 'redux-form';
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import renderField from '../../components/blocks/renderField';
import validate from '../helpers/debitValidation';

class DebitInformationForm extends React.Component {
    PropTypes = {
        formData: PropTypes.object,
        language: PropTypes.string,
        register: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            hasAlternateHolder: false
        };

        this.labels = {
            title: 'SEPA Mandaat',
            name: 'Naam',
            street: 'Straat',
            houseNumber: 'Huisnummer',
            appendix: 'Toevoeging',
            zipcode: 'Postcode',
            city: 'Woonplaats',
            country: 'Land',
            iban: 'IBAN',
            bic: 'BIC',
            bankName: 'Bank naam',
            location: 'Locatie',
            date: 'Datum',
            altHolderTitle: 'Alternatieve Rekeninghouder',
            altHolderQuestion: 'Is de rekeninghouder anders dan die op de factuur?',
            yes: 'Ja',
            no: 'Nee'
        };
    }

    componentDidMount() {
        const {initialized} = this.props;

        // Register form fields
        this.props.register('debitName');
        this.props.register('debitStreet');
        this.props.register('debitHouseNumber');
        this.props.register('debitAppendix');
        this.props.register('debitZipcode');
        this.props.register('debitCity');
        this.props.register('debitCountry');
        this.props.register('debitIban');
        this.props.register('debitBic');
        this.props.register('debitBankName');
        this.props.register('debitLocation');
        this.props.register('debitDate');
        this.props.register('hasAlternateHolder');
        this.props.register('altHolderName');
        this.props.register('altHolderStreet');
        this.props.register('altHolderZipcode');
        this.props.register('altHolderCity');
        this.props.register('altHolderCountry');

        // Set default country
        this.props.change('debitCountry', 'België');
        this.props.change('altHolderCountry', 'België');

        this.populateForm();
    }

    componentDidUpdate(prevProps, prevState) {
        const {formData, debit} = this.props;

        if (prevProps.formData.hasAlternateHolder !== formData.hasAlternateHolder) {
            this.setState({hasAlternateHolder: formData.hasAlternateHolder === 'yes'});
        }

        if (prevProps.debit !== debit) {
            this.populateForm();
        }
    }

    populateForm = () => {
        const {formData} = this.props;

        const {
            initials = '',
            lastname = '',
            street,
            housenr,
            appendix,
            zipcode,
            city,
            country,
            iban,
            bic,
        } = formData;

        this.props.change('debitName', `${initials} ${lastname}`.trim());
        this.props.change('debitStreet', street);
        this.props.change('debitHouseNumber', housenr);
        this.props.change('debitAppendix', appendix);
        this.props.change('debitZipcode', zipcode);
        this.props.change('debitCity', city);
        this.props.change('debitCountry', country || 'België');
        this.props.change('debitIban', iban);
        this.props.change('debitBic', bic);
    }

    render() {
        const {hasAlternateHolder} = this.state;

        return (
            <>
                <div className="form__category">
                    <h2 className="h2 card__title">{this.labels.title}</h2>

                    <div className="form__group">
                        <Field name="debitName" type="text" component={renderField} label={this.labels.name} />
                    </div>

                    <div className="form__group form__group--half flex-nowrap">
                        <Field name="debitStreet" type="text" component={renderField} label={this.labels.street} />
                        <div className="form__group form__group--half m-0">
                            <Field name="debitHouseNumber" type="text" component={renderField} label={this.labels.houseNumber} />
                            <Field name="debitAppendix" type="text" component={renderField} label={this.labels.appendix} />
                        </div>
                    </div>

                    <div className="form__group form__group--half">
                        <Field name="debitZipcode" type="text" component={renderField} label={this.labels.zipcode} />
                        <Field name="debitCity" type="text" component={renderField} label={this.labels.city} />
                    </div>

                    <div className="form__group">
                        <Field name="debitCountry" type="text" component={renderField} label={this.labels.country} />
                    </div>

                    <div className="form__group">
                        <Field name="debitIban" type="text" component={renderField} label={this.labels.iban} />
                    </div>

                    <div className="form__group form__group--half">
                        <Field name="debitBic" type="text" component={renderField} label={this.labels.bic} />
                        <Field name="debitBankName" type="text" component={renderField} label={this.labels.bankName} />
                    </div>

                    <div className="form__group form__group--half">
                        <Field name="debitLocation" type="text" component={renderField} label={this.labels.location} />
                        <Field name="debitDate" type="date" component={renderField} label={this.labels.date} />
                    </div>

                    <div className="form__group">
                        <p className="mb-4">{this.labels.altHolderQuestion}</p>
                        <div className="form__input-wrapper form__input-wrapper--radio form__input-wrapper--force-column">
                            <div className="form__radio-wrapper">
                                <Field component="input" type="radio" id="radio-alt-holder-yes" name="hasAlternateHolder" value="yes" />
                                <div className="form__radio" />
                                <label htmlFor="radio-alt-holder-yes" className="form__label">{this.labels.yes}</label>
                            </div>

                            <div className="form__radio-wrapper">
                                <Field component="input" type="radio" id="radio-alt-holder-no" name="hasAlternateHolder" value="no" />
                                <div className="form__radio" />
                                <label htmlFor="radio-alt-holder-no" className="form__label">{this.labels.no}</label>
                            </div>
                        </div>
                    </div>

                    {hasAlternateHolder && <>
                        <h3 className="h3 mt-4">{this.labels.altHolderTitle}</h3>

                        <div className="form__group">
                            <Field name="altHolderName" type="text" component={renderField} label={this.labels.name} />
                        </div>

                        <div className="form__group">
                            <Field name="altHolderStreet" type="text" component={renderField} label={this.labels.street} />
                        </div>

                        <div className="form__group form__group--half">
                            <Field name="altHolderZipcode" type="text" component={renderField} label={this.labels.zipcode} />
                            <Field name="altHolderCity" type="text" component={renderField} label={this.labels.city} />
                        </div>

                        <div className="form__group">
                            <Field name="altHolderCountry" type="text" component={renderField} label={this.labels.country} />
                        </div>
                    </>}
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    register: field => dispatch(registerField('wizard', field, 'Field')),
});

DebitInformationForm = reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    initialValues: {
        debitCountry: 'België',
        altHolderCountry: 'België',
        debitName: '',
        debitStreet: '',
        debitHouseNumber: '',
        debitAppendix: '',
        debitZipcode: '',
        debitCity: '',
        debitIban: '',
        debitBic: '',
        debitBankName: '',
        debitLocation: '',
        debitDate: '',
        hasAlternateHolder: 'no',
        altHolderName: '',
        altHolderStreet: '',
        altHolderZipcode: '',
        altHolderCity: ''
    },
    validate,
})(DebitInformationForm);

const selector = formValueSelector('wizard');
DebitInformationForm = connect(
    state => ({
        formData: getFormValues('wizard')(state),
    }),
    mapDispatchToProps
)(DebitInformationForm);

export default DebitInformationForm;
